import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Main from 'layouts/Main';
import Container from 'components/Container';
import StoryNaration from 'views/LiteraryClub/StoryNaration';
import SideBar1 from 'views/LiteraryClub/SideBar1';


const StoryKannada3to5= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Box>
       
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={9}>
            <StoryNaration />
            </Grid>
            <Grid item xs={12} md={3}>
            
                <Box marginBottom={4}>
                  <SideBar1/>
                </Box>
        
              {/* <SidebarNewsletter /> */}
            </Grid>
          </Grid>
        </Container>
       
      </Box>
    </Main>
  );
};

export default StoryKannada3to5;
